import React from 'react';
import SEO from '../../components/seo';
import BibliographyPageEN from '../../components/desktop/BibliographyPage/BibliographyPageEN';

const About = () => {
  return (
    <>
      <SEO title={'Bibliography'} />
      <BibliographyPageEN bgColor="#afa3c0" />
    </>
  );
};

export default About;
