import styled, {keyframes} from 'styled-components';
import bg from '../bg.png';

export const PageContainer = styled.div`
  width: 100%;
  background-color: ${({backgroundColor}) => backgroundColor};
  padding: 5rem 0;
  margin-top: -12px;
  position: relative;

  .second-parallax {
    background-image: url(${bg});
    background-position: center 99%;
    background-size: 37%;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
      background-size: 50%;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  position: relative;

  @media (max-width: 1024px) {
    padding: 0 40px;
  }
`;

export const TitlePage = styled.h3`
  color: #525252;
  font-family: 'RENNERBLACK';
  font-size: 4rem;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 328.44px;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 1px;
  white-space: nowrap;
  transform: translateX(-50%) translateY(-80%);
  line-height: 1;

  @media (max-width: 500px) {
    font-size: 3rem;
  }

  @media (max-width: 380px) {
    font-size: 2.8rem;
  }
`;

export const TitlePageEN = styled.h3`
  color: #525252;
  font-family: 'RENNERBLACK';
  font-size: 4rem;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 328.44px;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 1px;
  white-space: nowrap;
  transform: translateX(-50%) translateY(-80%);
  line-height: 1;

  @media (max-width: 500px) {
    font-size: 2.8rem;
  }

  @media (max-width: 380px) {
    font-size: 2.6rem;
  }
`;

export const ColorWrapper = styled.div`
  background-color: ${({bgColor}) => bgColor};
  padding: 2rem 0;
  position: relative;
  color: #ffffff;
  font-family: 'LATOREGULAR';
  font-size: 0.8rem;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.8;
`;

export const ImageWrapper = styled.div`
  background-image: url(${({srcBg}) => srcBg});
  background-size: cover;
  background-position: center top;
  width: 100%;
  height: 62vh;
  margin-top: calc(-5rem - 12px);
`;

export const PositionsList = styled.ul`
  width: 60%;
  margin: 0 auto;
  list-style-type: disc;

  li {
    margin: 1rem 0;
  }

  @media (max-width: 1024px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
